<template>
  <div class="absolute w-full h-full overflow-hidden">
    <div class="absolute w-full h-full flex justify-center items-center">
      <!-- 背景圖片 -->
      <img
        :src="imageURL"
        alt="This is a background image"
        class="w-full h-full bg-cover"
      >
    </div>
    <div
      :class="navigateBlockClass"
      @click="redirect"
    >
      <div class="absolute inset-0 bg-gray-200 opacity-20 hover:opacity-30 duration-500"></div>
      <div
        class="absolute text-3xl z-10 pointer-events-none"
        :class="titleClass"
      >
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeNavigate",
  props: {
    imageURL: String,
    title: String,
    navigateBlockDirection: {
      type: String,
      default: function () {
        return "right";
      },
    },
    redirectRouteName: {
      type: String,
      default: function () {
        return "Home";
      },
    },
  },
  methods: {
    redirect() {
      this.$router.push({ name: this.redirectRouteName });
    },
  },
  computed: {
    navigateBlockClass: function() {
      if (this.navigateBlockDirection == "right") {
        return "absolute w-2/5 h-full top-0 right-0 flex items-center backdrop-filter backdrop-blur transform translate-x-1/3 hover:translate-x-0 duration-500";
      } else if (this.navigateBlockDirection == "left") {
        return "absolute w-2/5 h-full top-0 left-0 flex items-center backdrop-filter backdrop-blur transform -translate-x-1/3 hover:translate-x-0 duration-500";
      } else {
        return "hidden";
      }
    },
    titleClass: function() {
      if (this.navigateBlockDirection == "right") {
        return "left-1/3";
      } else if (this.navigateBlockDirection == "left") {
        return "right-1/3";
      } else {
        return "hidden";
      }
    },
  }
};
</script>
