<template>
  <div class="absolute w-full h-full">
    <div class="w-full h-full z-0">
      <!-- 背景圖片 -->
      <img
        src="/images/milestone_background.png"
        alt=""
      />
    </div>
    <div class="absolute inset-0 flex justify-center items-end">
      <div class="relative w-4/5 h-4/5">
        <div class="relative w-full h-1/3 grid grid-cols-4">
          <div class="flex justify-center items-center">
            <div
              class="w-4/5 h-4/5 bg-red-300"
              @click="test = !test"
            >

            </div>
          </div>
          <div class="flex justify-center items-center">
            <div
              class="w-4/5 h-4/5 bg-blue-300"
              @click="test = true"
            >

            </div>
          </div>
          <div class="flex justify-center items-center">
            <div
              class="w-4/5 h-4/5 bg-green-300"
              @click="test = true"
            >

            </div>
          </div>
          <div class="flex justify-center items-center">
            <div
              class="w-4/5 h-4/5 bg-yellow-300"
              @click="test = true"
            >

            </div>
          </div>
        </div>
        <div class="w-full h-2/3">
          <div
            class="relative w-full h-full"
            v-if="test"
          >
            <MilestoneContent />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MilestoneContent from "../components/MilestoneContent";

export default {
  name: "Milestone",
  components: {
    MilestoneContent,
  },
  data() {
    return {
      test: false,
    };
  },
};
</script>
