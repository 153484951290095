<template>
	<div class="relative">
		<svg class="svg-icon absolute w-full h-full">
			<use :xlink:href="`#${iconName}`" />
		</svg>
	</div>
</template>

<script>
export default {
	name: "SvgIcon",
	props: {
		iconName: {
			type: String,
			required: true,
		},
	},
};
</script>

<style scoped>
.svg-icon {
	fill: currentColor !important;
}
</style>