<template>
  <div class="relative w-full h-full">
    <swiper
      ref="swiper"
      class="w-full h-full"
      :options="swiperOptions"
    >
      <swiper-slide>
        123
      </swiper-slide>
      <swiper-slide>
        456
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/js/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import 'swiper/css/swiper.css'
SwiperClass.use([Pagination, Mousewheel, Autoplay])
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
export default {
  name: "MilestoneContent",
  props: {
    data: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 20,
        loop: false,
        direction: 'vertical',
        mousewheel: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
    }
  }
}
</script>
