<template>
  <div class="trans-container select-none" :class="{ forwarding: this.$store.state.loaded }">
    <div class="trans-loading" :class="{ fade: this.$store.state.loaded }">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512">
        <g>
          <path d="M224.549,199.34c47.758,17.158,53.776,52.878,53.689,91.12c44.854,13.569,65.123,42.839,60.375,84.905c-2.737,24.248-13.011,42.158-38.212,48.144c-22.777,5.411-43.589-5.038-57.369-28.583c-2.715-4.639-4.431-9.863-6.413-14.372c13.361-12.69,13.361-12.69,51.914,13.947c4.661-8.124,13.51-16.575,12.507-23.622c-1.171-8.225-9.413-18.246-17.257-22.231c-32.726-16.628-79.365,10.968-82.7,47.159c-1.712,18.574,4.52,32.636,20.685,41.661c18.425,10.286,37.223,19.905,55.864,29.805c-40.063,21.023-84.969,10.531-109.707-25.644c-27.071-39.588-22.487-79.23,12.988-111.112c7.518-6.757,15.521-12.973,23.073-19.693c28.244-25.134,38.786-55.198,25.473-91.935C227.725,214.103,226.932,208.975,224.549,199.34z"/>
          <path d="M69.51,228.748c36.832,31.218,29.559,72.175,28.095,114.431c8.954-3.668,14.918-6.111,28.308-11.596c-42.915,80.829,0.273,129.309,60.219,173.2C93.73,511.088,37.56,442.088,64.568,360.701c10.458-31.514,13.273-62.569,7.263-95.043C69.603,253.624,70.201,241.067,69.51,228.748z"/>
          <path d="M250.087,512c96.428-18.6,124.164-33.325,113.834-188.882c6.945,10.337,11.387,16.949,18.15,27.015c9.371-19.354,16.011-38.125,26.861-54.021c10.358-15.175,25.102-27.356,37.918-40.853c2.459,1.758,4.917,3.515,7.376,5.273c-2.872,6.845-6.492,13.479-8.494,20.571c-11.451,40.573-27.288,80.64-32.62,122.04c-8.446,65.57-31.46,94.187-97.31,102.918C294.017,508.949,271.998,510.071,250.087,512z"/>
          <path d="M262.45,0c16.759,33.862,16.27,62.34-4.759,92.321c9.875,6.842,18.403,13.021,27.198,18.792c21.963,14.41,22.694,36.318,1.546,50.598c-24.389,16.468-69.551,10.996-89.302-10.821c-14.381-15.885-14.134-34.627,0.645-48.933C242.54,58.63,242.54,58.631,262.45,0z"/>
          <path d="M196.453,275.28c-41.529,6.145-91.674-40.792-101.545-95.05C158.45,184.865,196.14,220.144,196.453,275.28z"/>
          <path d="M302.895,276.378c-7.411-75.29,51.49-86.778,99.682-108.724C403.726,214.168,351.274,268.621,302.895,276.378z"/>
        </g>
      </svg>
    </div>
    <div class="trans-torii">
      <embed src="/svg/torii.svg">
    </div>
    <div class="trans-cloud trans-cloud-1">
      <embed src="/svg/cloud-1.svg">
    </div>
    <div class="trans-cloud trans-cloud-2">
      <embed src="/svg/cloud-2.svg">
    </div>
    <div class="trans-cloud trans-cloud-3">
      <embed src="/svg/cloud-3.svg">
    </div>
    <div class="trans-cloud trans-cloud-4">
      <embed src="/svg/cloud-4.svg">
    </div>
    <div class="trans-cloud trans-cloud-5">
      <embed src="/svg/cloud-5.svg">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Transition'
}
</script>

<style lang="less">
@import '../assets/less/transition.module';
</style>
