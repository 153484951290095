<template>
  <headroom :zIndex="zIndex">
    <header class="px-4 py-2 bg-background flex justify-between">
      <div class="text-primary flex justify-center items-center">
        <icon iconName="menu" class="w-10 h-10 fill-current" />
      </div>
      <div class="flex flex-row justify-center items-center">
        <router-link to="/home" custom v-slot="{ navigate }">
          <button class="mx-2 text-primary font-bold focus:outline-none" @click="navigate" @keypress.enter="navigate">Home</button>
        </router-link>
        <router-link to="/milestone" custom v-slot="{ navigate }">
          <button class="mx-2 text-primary font-bold focus:outline-none" @click="navigate" @keypress.enter="navigate">Milestone</button>
        </router-link>
        <router-link to="/message" custom v-slot="{ navigate }">
          <button class="mx-2 text-primary font-bold focus:outline-none" @click="navigate" @keypress.enter="navigate">Message Board</button>
        </router-link>
      </div>
    </header>
  </headroom>
</template>

<script>
import { headroom } from "vue-headroom";

export default {
  name: "Header",
  components: {
    headroom,
  },
  data() {
    return {
      "zIndex": 1,
      // headerIsShow: false,
    };
  },
  mounted() {},
  unmounted() {
    // window.removeEventListener("scroll", this.isScroll);
  },
  methods: {
    // isScroll() {
    // 	const top = document.documentElement.scrollTop;
    // 	if (top > 100) {
    // 		this.headerIsShow = false;
    // 	} else {
    // 		this.headerIsShow = true;
    // 	}
    // },
  },
};
</script>