<template>
  <div class="master font-KTEGAKI">
    <Transition/>
    <Locale/>
    <!-- <router-view/> -->
    <Main/>
  </div>
</template>

<script>
//import Banner from './components/Banner.vue'
import Transition from './components/Transition.vue'
import Locale from './components/Locale.vue'
import Main from './components/Main.vue'

export default {
  name: 'App',
  components: {
    //Banner,
    Transition,
    Locale,
    Main
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        setTimeout(() => {
          this.$store.dispatch('setLoaded', true);
        }, 1500);
      } 
    }
    /*this.$nextTick(() => {
      this.$store.dispatch('setLoaded', true);
    });*/
  }
}
</script>

<style lang="less">
@import './assets/less/common.module';
</style>
