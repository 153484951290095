<template>
  <div class="main w-full h-full" :class="{ active: this.$store.state.loaded }">
    <Header></Header>
    <transition name="main">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import Header from "../components/Header";

export default {
  name: "Main",
  components: {
		Header,
	},
  computed: {
    active: function () {
      return this.$store.state.scrollDown;
    },
  }
};
</script>