import Vue from 'vue'
import VueRouter from "vue-router";
import routes from "./routes/index.js";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'is-active',
  routes,
});

export default router;