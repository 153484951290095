import Home from '../pages/Home';
import Message from '../pages/Message';
import Milestone from '../pages/Milestone';

const routesBase = [
  {
    path: '/home', name: 'home',
    component: Home,
  },
  {
    path: '/milestone', name: 'milestone',
    component: Milestone,
  },
  {
    path: '/message', name: 'message',
    component: Message
  }
];

const routesDefault = [
  {
    path: '/*', redirect: { name: 'home' },
  }
];

const routesAll = [
  ...routesBase,
  ...routesDefault,
];

export default routesAll;