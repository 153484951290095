<template>
  <div class="w-full box-border pt-16 select-none sticky flex justify-center">
    <div class="flex flex-col sm:grid grid-rows-6 grid-flow-col justify-items-stretch gap-4">
      <div class="send-notice sm:row-span-6 bg-red-200 box-border rounded-lg p-4">
        說明事項
      </div>
      <div class="sm:col-span-2 h-10 border-b-2 inline-flex items-center justify-center sm:justify-end">
        <div v-if="twitterAuthed" class="rounded-full inline-flex items-center">
          <div class="mr-1"><img class="rounded-full w-6 h-6" :src="twitterAvatar"></div>
          <div class="max-w-min mr-1">{{ twitterScreenUsername }}</div>
          <div @click="twitterLogout" class="rounded-lg bg-red-400 p-1 cursor-pointer">登出</div>
        </div>
        <div v-else class="twitter-login inline-flex items-center rounded-lg bg-blue-400 p-1 cursor-pointer text-white">
          <svg width="20" height="20" fill="#ffffff" class="inline">
            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"></path>
          </svg>
          <span @click="twitterLogin" class="inline ml-1">Login with Twitter</span>
        </div>
      </div>
      <div class="send-form sm:row-span-5 sm:col-span-2 bg-red-200 box-border rounded-lg p-4">
        <textarea v-if="twitterAuthed" class="send-content" placeholder="輸入留言..." ></textarea>
        <textarea v-else class="send-content" placeholder="請先登入Twitter" disabled></textarea>
        <div v-if="twitterAuthed" class="send-button rounded-lg bg-blue-400 p-1 cursor-pointer text-white">{{ $t("send_message") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  name: "ShowMessage",
  data() {
    return {
      twitterAuthed: false,
      twitterScreenUsername: '',
      twitterAvatar: ''
    }
  },
  mounted() {
    let token = this.getToken(64);
    this.getUser(token);
  },
  methods: {
    twitterLogin() {
      let token = this.getToken(64);
      let windowFeatures = `
        width=600,
        height=600
      `;
      let authWindow = window.open(`https://api.ayamekawayo.moe/v1/twitter/login?token=${token}`, 'Twitter Authentication', windowFeatures);
      let checker = setInterval(() => { 
        if(authWindow.closed) {
            clearInterval(checker);
            let authed = this.getTwitterToken(token);
            if(authed) this.getUser(token);
        }
      }, 500);
    },
    twitterLogout() {
      this.twitterAuthed = false;
      window.localStorage.removeItem("token");
    },
    getToken(length) {
      let localToken = window.localStorage.getItem("token");
      if(localToken != null) {
        try {
          localToken = JSON.parse(localToken);
        }catch (error) {
          window.localStorage.removeItem("token");
          localToken = null;
          console.log(error);
        }
      }
      let tokenreg = /^([a-zA-Z\d])*$/;
      let now = new Date().getTime();
      if(localToken !== null && tokenreg.test(localToken.token) && localToken.token.length === 64 && localToken.expiry+3590*1000 >= now) {
        return localToken.token
      }else {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        let item = {
          'token': result,
          'expiry': now
        }
        window.localStorage.setItem('token', JSON.stringify(item));
        return result;
      }
    },
    async getTwitterToken(token) {
      let authed = false;
      axios
        .get('https://api.ayamekawayo.moe/v1/twitter/token', {
          params: {
            token
          }
        })
        .then(response => {
          if(response.data.message !== null) {
            let twitterToken = this.getCookie()['twitterToken'];
            console.log(twitterToken);
            if(twitterToken == undefined || response.data.message != twitterToken) {
              let expire = new Date (JSON.parse(window.localStorage.getItem('token')).expiry+3590*1000).toGMTString();
              document.cookie = `twitterToken=${response.data.message}; expires=${expire}; SameSite=Lax; Secure`;
            }
            authed = true;
          }
        });
      return authed;
    },
    async getUser(token) {
      console.log(token);
      axios
        .get('https://api.ayamekawayo.moe/v1/twitter/getuser', {
          params: {
            token
          }
        })
        .then(response => {
          if(response.data.message !== null) {
            this.twitterAuthed = true;
            let userdata = JSON.parse(response.data.message);
            this.twitterAvatar = userdata.Avatar;
            this.twitterScreenUsername = userdata.ScreenName;
          }else {
            this.twitterAuthed = false;
            window.localStorage.removeItem("token");
          }
        })
    },
    getCookie() {
      let cookie = document.cookie.split('; ');
      let result = {};
      for (var i = 0; i < cookie.length; i++) {
        var cur = cookie[i].split('=');
        result[cur[0]] = cur[1];
      }
      return result;
    }
  },
};
</script>