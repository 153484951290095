import {app} from '../main'
import * as types from './mutation-types'

export const state = {
  lang: 'jp',
  scrollDown: false,
  loaded: false
}

export const mutations = {
  [types.SET_LANG] (state, payload) {
    state.lang = payload;
    app.$i18n.locale = payload;
  },
  [types.SET_SCROLL_DOWN] (state, payload) {
    state.scrollDown = payload;
  },
  [types.SET_LOADED] (state, payload) {
    state.loaded = payload;
  }
}

export const actions = {
  async setLoaded({commit}, payload){
    commit(types.SET_LOADED, payload);
  },
  async setScrollDown({commit}, payload){
    commit(types.SET_SCROLL_DOWN, payload);
  },
  async switchLang({commit}, payload){
    if (payload in app.$i18n.messages) {
      commit(types.SET_LANG, payload);
    } else {
      try {
        const res = await require(`../assets/lang/${payload}.json`);
        app.$i18n.setLocaleMessage(payload, res);
        commit(types.SET_LANG, payload);
      }
      catch(e) {
        console.log(e);
      }
    }
  }
}