import Vue from 'vue'
import App from './App.vue'
import i18n from './assets/lang/lang'
import {VueHammer} from 'vue2-hammer'
import store from './store'
import router from './router'
import AOS from 'aos'
import 'aos/dist/aos.css'
import "tailwindcss/tailwind.css"
import SvgIcon from "@/components/SvgIcon"

Vue.use(VueHammer)

Vue.config.productionTip = false

Vue.component("icon", SvgIcon)

export const app = new Vue({
  el: '#app',
  store,
  i18n,
  render: h => h(App),
  router,
  created () {
    AOS.init();
  }
})

window['vue'] = app
window.store = store

const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context("@/assets/icon", true, /\.svg$/)
requireAll(req)