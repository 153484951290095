<template>
  <div class="absolute top-0 w-full h-full">
    <ShowMessage></ShowMessage>
    <SendMessage></SendMessage>
  </div>
</template>

<script>
import ShowMessage from "../components/ShowMessage";
import SendMessage from "../components/SendMessage";

export default {
  name: "Message",
  components: {
    ShowMessage,
    SendMessage
  },
};
</script>

<style lang="less">
@import '../assets/less/message.module';
</style>