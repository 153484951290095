import Vue from 'vue'
import VueI18n from 'vue-i18n'

import jp from './jp.json'

Vue.use(VueI18n)

const locale = 'jp'

const messages = {jp}

const i18n = new VueI18n({
    locale,
    messages
})

export default i18n
