<template>
  <div class="absolute w-full h-full">
    <HomeNavigate
      imageURL="/images/background.jpg"
      title="Milestone"
      navigateBlockDirection="right"
      redirectRouteName="milestone"
    ></HomeNavigate>
  </div>
</template>

<script>
import HomeNavigate from "../components/HomeNavigate";

export default {
  name: "Home",
  components: {
    HomeNavigate,
  }
}
</script>
